import React, { useState } from "react"
import Logo from "./Logo"
import Menu from "./Menu"
import { Toggle } from "./Toggle"

const Header = () => {

    const [isActive, setActive] = useState(false)

    return (
        <header className="bg-zone-1 border-y-2 border-solid border-zone-5 py-2">
            <nav className="container mx-auto relative flex flex-row flex-wrap items-center justify-between lg:flex-nowrap">
                <Logo className="ml-2 xl:ml-0" />

                <Toggle className="border border-zone-1 w-8 h-8 rounded-xl flex justify-center items-center lg:order-last lg:mr-2 lg:ml-3 xl:mr-0" />

                <button aria-label="Toggle Menu" className="mr-2 xl:mr-0 py-2 lg:hidden" onClick={() => setActive(!isActive)}>
                    <div className={`bg-zone-8 w-7 h-1 mb-1 transition-transform ${isActive ? 'transform rotate-45 translate-x-1 translate-y-1' : null}`}></div>
                    <div className={`bg-zone-8 w-7 h-1 mb-1 transition-transform ${isActive ? 'transform -rotate-45 translate-x-1 -translate-y-1' : null}`}></div>
                    <div className={`bg-zone-8 w-7 h-1 ${isActive ? 'hidden' : null}`}></div>
                </button>

                <Menu className={`${isActive ? 'block' : 'hidden'} flex-100 lg:flex-auto lg:block`} />
            </nav>
        </header>
    )
}

export default Header