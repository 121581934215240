import React from 'react'
import { useDarkMode } from '../hooks/useDarkMode'
//assets
import srcDark from "../images/dark.svg"
import srcLight from "../images/light.svg"

export const Toggle = ({ className = null }) => {
    const [isDark, setIsDark] = useDarkMode()

    const iconDark = <img src={srcDark} alt="dark" width="16" height="16" />
    const iconLight = <img src={srcLight} alt="light" width="18" height="18" />
    
    return (
        <button
            aria-label={isDark ? 'Activate Light Mode' : 'Activate Dark Mode'}
            title={isDark ? 'Activate Light Mode' : 'Activate Dark Mode'}
            onClick={() => {
                setIsDark(!isDark)
            }}
            className={className}
            id="button-dark-mode"
        >
            {isDark ? iconLight : iconDark}
        </button>
    )
}