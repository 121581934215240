import React, { useEffect, useState } from 'react'

const GoTop = () => {
    const [buttonClass, setButtonClass] = useState("hidden")

    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    //scroll listener
    useEffect(() => {
        const handleVisibleButton = () => {
            const position = window.pageYOffset

            if (position > 150) {
                return setButtonClass("block")
            } else if (position < 150) {
                return setButtonClass("hidden")
            }
        }

        window.addEventListener("scroll", handleVisibleButton)
        return _ => {
            window.removeEventListener("scroll", handleVisibleButton)
        }
    }, [])

    return (
        <button onClick={() => handleScrollTop()} className={` ${buttonClass} w-12 h-12 fixed bottom-4 right-4 bg-zone-9 hover:bg-zone-6 rounded-2xl z-50`}>
            <span className="transform -rotate-90 flex justify-center items-center text-white text-5xl mr-3">
                &rsaquo;
            </span>
        </button>
    )
}

export default GoTop
