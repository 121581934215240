import React from "react"
import { Link } from 'gatsby'
import iconLogo from "../images/mattress-logo.svg"

const Logo = ({ className = '' }) => {
    return (
        <Link className={className} to="/">
            <img src={iconLogo} alt="MattressZone" width="50" height="50" />
        </Link>
    )
}

export default Logo
