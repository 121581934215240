import React/*, { useState }*/ from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useMenuLinks } from "../hooks/useMenuLinks"
import { Link } from 'gatsby'
import { getVariables } from "../lib/utils"

const Menu = ({ className = null }) => {
    let variables = getVariables()
    //const [ toggle, setToggle ] = useState(false)
    const { flagImage, country } = useMenuLinks()
    const image = getImage(flagImage)

    return (
        <div className={className}>
            <ul className="border-t-2 border-solid border-zone-1 mt-3 lg:mt-0 lg:border-none flex flex-col lg:flex-row lg:justify-end">
                <li className="flex items-center"><Link className="block py-2 px-4 text-zone-1" to="/">{variables.home}</Link></li>
                <li className="flex items-center"><Link className="block py-2 px-4 text-zone-1 capitalize" to={`/${variables.mattresses}`}>{variables.compare}</Link></li>
                {/*}
                <li className="flex items-center"><Link className="block py-2 px-4 text-zone-1 capitalize" to="/deals">Deals</Link></li>
                <li className="relative">
                    <div className="flex justify-between items-center">
                        <Link className="block py-2 pl-4 capitalize text-zone-1" to="/reviews">{variables.reviews}</Link>
                        <button className="px-4 py-2 font-bold text-2xl text-zone-1" onClick={() => setToggle(!toggle)}>+</button>
                    </div>
                    <div className={`${ toggle ? 'block' : 'hidden' } m-4 mt-0 border border-zone-4 rounded lg:absolute lg:z-30 lg:bg-zone-1 lg:m-0 lg:w-60 lg:-left-1/3 lg:mt-2`}>
                        <a className="block py-2 px-4 text-zone-1" href="#">Action</a>
                        <a className="block py-2 px-4 text-zone-1" href="#">Another action</a>
                        <hr />
                        <a className="block py-2 px-4 text-zone-1" href="#">Something else here</a>
                    </div>
                </li>
                */}
                <li className="flex items-center"><a className="block py-2 px-4 text-zone-1" href="https://mattress.zone/about-us">{variables.about_us}</a></li>
                <li className="flex items-center">
                    <a className="block py-2 px-4 text-zone-1" href="https://mattress.zone/" target="_blank" rel="noreferrer">
                        {country.countryName}
                        <GatsbyImage image={image} alt={country.countryName} className="ml-2 border border-zone-1 rounded-full" />
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Menu