import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import HelloBar from "./HelloBar"

const Layout = ({ children }) => {
    
    return (
        <div>
            <HelloBar />
            <Header />
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout